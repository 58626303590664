import React from "react"
import { Link } from "gatsby"

import ComponentIcon from "../component-icon/componentIcon"
import ReadMore from "../read-more/readMore"
import { kebabCase } from "../../utils/utils"

import "./componentDescription.scss"

var ComponentDescription = props => (
  <div
    className={`component-description-component ${props.className || ""} ${
      props.card ? "card" : ""
    }`}
  >
    <Link
      className="link"
      to={`/${props.componentsDir}/${kebabCase(props.pageName)}`}
    >
      <div className="body">
        <div className="left">
          <ComponentIcon icon={props.icon} />
        </div>
        <div className="right">
          <h3>{props.title}</h3>
          <div className="text">
            <p>{props.description}</p>
          </div>
        </div>
      </div>
      {props.readMore && <ReadMore noLink />}
    </Link>
  </div>
)

export default ComponentDescription
