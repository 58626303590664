import HeartPulseIcon from "mdi-react/HeartPulseIcon"
import FoodAppleIcon from "mdi-react/FoodAppleIcon"
import CashIcon from "mdi-react/CashIcon"
import CellPhoneIcon from "mdi-react/CellPhoneIcon"
import PillIcon from "mdi-react/PillIcon"
import BriefCaseSearchIcon from "mdi-react/BriefCaseSearchIcon"
import RunIcon from "mdi-react/RunIcon"
import AccountGroupIcon from "mdi-react/AccountGroupIcon"
import ChurchIcon from "mdi-react/ChurchIcon"
import BusSideIcon from "mdi-react/BusSideIcon"
import StoreIcon from "mdi-react/StoreIcon"
import TrainCarIcon from "mdi-react/TrainCarIcon"
import CharityIcon from "mdi-react/CharityIcon"

import jsonOne from "./programComponentsYearOne.json"
import jsonTwo from "./programComponentsYearTwo.json"

var icons = {
  HeartPulseIcon: HeartPulseIcon,
  FoodAppleIcon: FoodAppleIcon,
  CashIcon: CashIcon,
  CellPhoneIcon: CellPhoneIcon,
  PillIcon: PillIcon,
  BriefCaseSearchIcon: BriefCaseSearchIcon,
  RunIcon: RunIcon,
  AccountGroupIcon: AccountGroupIcon,
  ChurchIcon: ChurchIcon,
  BusSideIcon: BusSideIcon,
  StoreIcon: StoreIcon,
  TrainCarIcon: TrainCarIcon,
  CharityIcon: CharityIcon,
}

var componentsYearOne = jsonOne.map(obj => ({
  icon: icons[obj.iconName],
  ...obj,
}))
var componentsYearTwo = jsonTwo.map(obj => ({
  icon: icons[obj.iconName],
  ...obj,
}))

export { componentsYearOne, componentsYearTwo }
