import React from "react"
import { Link } from "gatsby"

import "./readMore.scss"

var ReadMore = props =>
  props.noLink ? (
    <div className={`read-more-component ${props.className} || ""`}>
      READ MORE
    </div>
  ) : (
    <Link
      to={props.to}
      className={`read-more-component ${props.className} || ""`}
    >
      READ MORE
    </Link>
  )

export default ReadMore
